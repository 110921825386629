<script>
import pako from 'pako';
import { Base64 } from 'js-base64';

export default {
  mounted() {
    console.log('mounted');
    this.saveLogin();
    this.saveSign();
    this.saveNia();
  },
  data() {
    return {
      login: {
        keycloak_url: localStorage.getItem('login_keycloak_url') || 'http://localhost/realms/master',
        client_id: localStorage.getItem('login_client_id') || 'bankid-test',
        client_secret: localStorage.getItem('login_client_secret') || 'alLye4VKWqyR2gumFB8S64SsRv7gbPqQ',
        redirect_uri: localStorage.getItem('login_redirect_uri') || 'https://fed-auth-simulator.feldcode.cz/php/callback',
        response_type: localStorage.getItem('login_response_type') || 'code',
        scope: localStorage.getItem('login_scope') || 'openid profile',
      },
      sign: {
        client_id: localStorage.getItem('sign_client_id') || 'bankid-test',
        request_uri: localStorage.getItem('sign_request_uri') || '',
      },
      nia: {
        saml_request: localStorage.getItem('nia_saml_request') || '<?xml version="1.0" encoding="UTF-8"?>\n<samlp:AuthnRequest xmlns:samlp="urn:oasis:names:tc:SAML:2.0:protocol" ID="_696dd47b4f9b1f1431a15f0207928a852de97efb18" Version="2.0" IssueInstant="2024-05-20T12:05:24Z" ProtocolBinding="urn:oasis:names:tc:SAML:2.0:bindings:HTTP-POST" AssertionConsumerServiceURL="https://nia-tpp-mock-app.feldcode.cz/">\n <saml:Issuer xmlns:saml="urn:oasis:names:tc:SAML:2.0:assertion">http://devel.loc:8080/tests/wp-test/wp-content/plugins/miniorange-saml-20-single-sign-on/</saml:Issuer>\n <samlp:NameIDPolicy AllowCreate="true" Format="urn:oasis:names:tc:SAML:1.1:nameid-format:unspecified"></samlp:NameIDPolicy>\n</samlp:AuthnRequest>',
      },
      verifyEndpointResponsePayloadSet: window.verifyEndpointResponsePayloadSet ? window.verifyEndpointResponsePayloadSet : false,
      verifyEndpointResponsePayload: window.verifyEndpointResponsePayload ? window.verifyEndpointResponsePayload : '',
      confirmLogin: window.confirmLogin ? window.confirmLogin : null,
      loginSessionValues: window.loginSessionValues ? window.loginSessionValues : null,
    }
  },
  computed: {
    loginLink() {
      return `${this.login.keycloak_url}/protocol/openid-connect/auth?client_id=${encodeURIComponent(this.login.client_id)}&redirect_uri=${encodeURIComponent(this.login.redirect_uri)}&response_type=${encodeURIComponent(this.login.response_type)}&scope=${encodeURIComponent(this.login.scope)}`;
    },
    signLink() {
      return `${this.login.keycloak_url}/protocol/openid-connect/auth?client_id=${encodeURIComponent(this.sign.client_id)}&request_uri=${encodeURIComponent(this.sign.request_uri)}`;
    },
    niaLink() {
      return `${this.login.keycloak_url}/protocol/saml?SAMLRequest=${this.encodeSamlRequest(this.nia.saml_request)}`;
    },
    confirmLoginLink() {
      return this.confirmLogin ? `${this.login.keycloak_url}/${this.confirmLogin}` : null;
    },
  },
  methods: {
    saveLogin() {
      localStorage.setItem('login_keycloak_url', this.login.keycloak_url);
      localStorage.setItem('login_client_id', this.login.client_id);
      localStorage.setItem('login_client_secret', this.login.client_secret);
      localStorage.setItem('login_redirect_uri', this.login.redirect_uri);
      localStorage.setItem('login_response_type', this.login.response_type);
      localStorage.setItem('login_scope', this.login.scope);
    },
    saveSign() {
      localStorage.setItem('sign_client_id', this.sign.client_id);
      localStorage.setItem('sign_request_uri', this.sign.request_uri);
    },
    saveNia() {
      localStorage.setItem('nia_saml_request', this.nia.saml_request);
    },
    encodeSamlRequest(samlRequest) {
      // Compress the SAML request using DEFLATE
      const compressedRequest = pako.deflateRaw(samlRequest, { level: 9 });

      // Base64 encode the compressed request
      //const base64EncodedRequest = btoa(String.fromCharCode(...new Uint8Array(compressedRequest)));
      const base64EncodedRequest = Base64.fromUint8Array(compressedRequest);

      // URL encode the Base64 encoded request
      const urlEncodedRequest = encodeURIComponent(base64EncodedRequest);

      return urlEncodedRequest;
    },
    cancelLogin() {
      this.confirmLogin = null;
      window.location.href = '/';
    },
    resetDefaults() {
      localStorage.removeItem('login_keycloak_url', this.login.keycloak_url);
      localStorage.removeItem('login_client_id', this.login.client_id);
      localStorage.removeItem('login_client_secret', this.login.client_secret);
      localStorage.removeItem('login_redirect_uri', this.login.redirect_uri);
      localStorage.removeItem('login_response_type', this.login.response_type);
      localStorage.removeItem('login_scope', this.login.scope);
      localStorage.removeItem('sign_client_id', this.sign.client_id);
      localStorage.removeItem('sign_request_uri', this.sign.request_uri);
      localStorage.removeItem('nia_saml_request', this.nia.saml_request);

      window.location.reload();
    }
  }
}
</script>

<template>
  <div class="h-screen w-full bg-neutral-100 fixed t-0 l-0 flex flex-col justify-center items-center" v-if="!!confirmLogin">
    <pre>
      {{ loginSessionValues }}
    </pre>
    <div class="flex">
      <a :href="confirmLoginLink" class="btn bg-green-600 text-white hover:bg-green-700">Confirm Login</a>
      <button @click="cancelLogin" class="btn bg-neutral-400 text-white hover:bg-neutral-500">Cancel Login</button>
    </div>
  </div>

  <div class="container mx-auto p-4 grid grid-cols-1 md:grid-cols-3 gap-4">
    <div class="flex flex-col justify-between mb-4">
      <div class="flex-grow">
        <h2 class="text-2xl mb-2">BankiD Login Link</h2>
        <div class="mb-2">
          <label class="block">Keycloak URL:</label>
          <input v-model="login.keycloak_url" @input="saveLogin" class="input" type="text" />
        </div>
        <div class="mb-2">
          <label class="block">Client ID:</label>
          <input v-model="login.client_id" @input="saveLogin" class="input" type="text" />
        </div>
        <div class="mb-2">
          <label class="block">Client Secret (to obtain token later in callback):</label>
          <input v-model="login.client_secret" @input="saveLogin" class="input" type="text" />
        </div>
        <div class="mb-2">
          <label class="block">Redirect URI:</label>
          <input v-model="login.redirect_uri" @input="saveLogin" class="input" type="text" />
        </div>
        <div class="mb-2">
          <label class="block">Response Type:</label>
          <input v-model="login.response_type" @input="saveLogin" class="input" type="text" />
        </div>
        <div class="mb-2">
          <label class="block">Scope:</label>
          <input v-model="login.scope" @input="saveLogin" class="input" type="text" />
        </div>
      </div>
      <div class="flex flex-col items-stretch">
        <a :href="loginLink" class="btn bg-green-600 text-white hover:bg-green-700">BankiD Login</a>
        <label class="block">Link to copy:</label>
        <input class="input bg-neutral-200" onfocus="this.select();" :value="loginLink" readonly />
      </div>
    </div>

    <div class="flex flex-col justify-between mb-4">
      <div class="flex-grow">
        <h2 class="text-2xl mb-2">BankiD Sign Link</h2>
        <div class="mb-2">
          <label class="block">Keycloak URL:</label>
          <input v-model="login.keycloak_url" @input="saveLogin" class="input" type="text" />
        </div>
        <div class="mb-2">
          <label class="block">Client ID:</label>
          <input v-model="sign.client_id" @input="saveSign" class="input" type="text" />
        </div>
        <div class="mb-2">
          <label class="block">Request URI:</label>
          <input v-model="sign.request_uri" @input="saveSign" class="input" type="text" />
        </div>
      </div>
      <div class="flex flex-col items-stretch">
        <a :href="signLink" class="btn bg-green-600 text-white hover:bg-green-700">BankiD Sign Doc</a>
        <label class="block">Link to copy:</label>
        <input class="input bg-neutral-200" onfocus="this.select();" :value="signLink" readonly />
      </div>
    </div>

    <div class="flex flex-col justify-between mb-4">
      <div class="flex-grow">
        <h2 class="text-2xl mb-2">NIA Login</h2>
        <div class="mb-2">
          <label class="block">Keycloak URL:</label>
          <input v-model="login.keycloak_url" @input="saveLogin" class="input" type="text" />
        </div>
        <div class="mb-2">
          <label class="block">SAML Request:</label>
          <textarea v-model="nia.saml_request" @input="saveNia" class="input" rows="10"></textarea>
        </div>
      </div>
      <div class="flex flex-col items-stretch">
        <a :href="niaLink" class="btn bg-green-600 text-white hover:bg-green-700">NIA Login</a>
        <label class="block">Link to copy:</label>
        <input class="input bg-neutral-200" onfocus="this.select();" :value="niaLink" readonly />
      </div>
    </div>
  </div>

  <div class="container mx-auto p-4 flex-grow w-100">
    <hr />
    <h2 class="text-2xl text-center"><i class="mdi mdi-cogs text-3xl"></i> Nastavení</h2>
  </div>

  <div class="container mx-auto p-4 grid grid-cols-1 md:grid-cols-3 gap-4">
    <div class="flex flex-col justify-between mb-4">
      <h2 class="text-xl text-center">Vstupní hodnoty pro odkazy:</h2>
      <button @click="resetDefaults" class="btn bg-neutral-100 hover:bg-neutral-300">Reset defaults (výchozí hodnoty)</button>
    </div>

    <div class="flex flex-col justify-between mb-4">
      <h2 class="text-xl text-center">/verify Endpoint Response (piduuid)</h2>
      <div class="text-green-500" v-if="verifyEndpointResponsePayloadSet">
        <i class="mdi mdi-check"></i> Nastaveno
      </div>
      <form method="post" action="/">
        <textarea name="verify_endpoint_response_payload" class="input" rows="3" v-model="verifyEndpointResponsePayload"></textarea>
        <button class="btn bg-neutral-100 hover:bg-neutral-300 w-full">Nastavit JSON Payload /verify endpointu</button>
      </form>
    </div>
  </div>
</template>

<style scoped>
.input {
  width: 100%;
  padding: 8px;
  margin: 4px 0;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
}
.btn {
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
}
</style>
